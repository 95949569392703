@color_1: #e9e6e4;
@color_2: inherit;
@color_3: #0088cc;
@color_4: #dbd2d2;
@color_5: white;
@color_6: #9a1313;
@color_7: darkred;
@color_8: #0077b3;
@color_9: #3f51b5;
@color_10: black;
@color_11: #fff;
@color_12: #363f45;
@color_13: green;
@color_14: blue;
@color_15: #FF0522;
@color_16: #4D90FD;
@color_17: greenyellow;
@color_18: red;
@color_19: #ff564e;
@color_20: #e34a49;
@color_21: #dce0f3;
@color_22: #353333;
@color_23: #f1443c;
@color_24: #555;
@color_25: #585f69;
@font_family_1: 'Open Sans', sans-serif;
@font_family_2: muli;
@font_family_3: serif;
@font_family_4: -webkit-body;
@background_color_1: rgba(170,170,170,0.4);
@background_color_2: transparent;
@background_color_3: rgba(0,0,0,0.4);
@background_color_4: rgba(128, 128, 128, 0.62);
@background_color_5: darkred;
@background_color_6: #dddddd;
@background_color_7: #f9564d;
@background_color_8: #f9564c;
@background_color_9: #F5F5F5;
@background_color_10: #eceeee5e;
@background_color_11: #4caf509c;
@background_color_12: red;
@background_color_13: rgb(247, 133, 112);
@background_color_14: rgb(163, 161, 255);
@background_color_15: rgb(197, 169, 86);
@background_color_16: rgb(75, 164, 197);
@background_color_17: #d8d8d8;
@background_color_18: rgba(255,255,255,0.8);
@background_color_19: #e6e9ee;
@border_color_1: rgb(249, 86, 76);
@border_color_2: rgba(255, 255, 255, 0);
@border_color_3: rgba(194, 225, 245, 0);
@border_color_4: transparent;
@border_color_5: #f1443d;
@border_color_6: #eee6e6;
@border_top_color_1: whitesmoke;
@border_top_color_2: #ffffff;
@border_top_color_3: transparent;
@border_right_color_1: transparent;
@border_right_color_2: #ddd;
@border_bottom_color_1: #ddd;
@border_bottom_color_2: transparent;
@border_bottom_color_3: #f1443d;
@border_left_color_1: transparent;
@border_left_color_2: #ddd;

/* css for tabs start*/
/* custom inclusion of right, left and below tabs

.tabs-below > .nav-tabs,
.tabs-right > .nav-tabs,
.tabs-left > .nav-tabs {
  border-bottom: 0;
}

.tab-content > .tab-pane,
.pill-content > .pill-pane {
  display: none;
}

.tab-content > .active,
.pill-content > .active {
  display: block;
}

.tabs-below > .nav-tabs {
  border-top: 1px solid #ddd;
}

.tabs-below > .nav-tabs > li {
  margin-top: -1px;
  margin-bottom: 0;
}

.tabs-below > .nav-tabs > li > a {
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
}

.tabs-below > .nav-tabs > li > a:hover,
.tabs-below > .nav-tabs > li > a:focus {
  border-top-color: #ddd;
  border-bottom-color: transparent;
}

.tabs-below > .nav-tabs > .active > a,
.tabs-below > .nav-tabs > .active > a:hover,
.tabs-below > .nav-tabs > .active > a:focus {
  border-color: transparent #ddd #ddd #ddd;
}

.tabs-left > .nav-tabs > li,
.tabs-right > .nav-tabs > li {
  float: none;
}

.tabs-left > .nav-tabs > li > a,
.tabs-right > .nav-tabs > li > a {
  min-width: 74px;
  margin-right: 0;
  margin-bottom: 3px;
}

.tabs-left > .nav-tabs {
  float: left;
  margin-right: 19px;
  border-right: 1px solid #ddd;
}

.tabs-left > .nav-tabs > li > a {
  margin-right: -1px;
  -webkit-border-radius: 4px 0 0 4px;
  -moz-border-radius: 4px 0 0 4px;
  border-radius: 4px 0 0 4px;
}

.tabs-left > .nav-tabs > li > a:hover,
.tabs-left > .nav-tabs > li > a:focus {
  border-color: #eeeeee #dddddd #eeeeee #eeeeee;
}

.tabs-left > .nav-tabs .active > a,
.tabs-left > .nav-tabs .active > a:hover,
.tabs-left > .nav-tabs .active > a:focus {
  border-color: #ddd transparent #ddd #ddd;
  *border-right-color: #ffffff;
}

.tabs-right > .nav-tabs {
  float: right;
  margin-left: 19px;
  border-left: 1px solid #ddd;
}

.tabs-right > .nav-tabs > li > a {
  margin-left: -1px;
  -webkit-border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
}

.tabs-right > .nav-tabs > li > a:hover,
.tabs-right > .nav-tabs > li > a:focus {
  border-color: #eeeeee #eeeeee #eeeeee #dddddd;
}

.tabs-right > .nav-tabs .active > a,
.tabs-right > .nav-tabs .active > a:hover,
.tabs-right > .nav-tabs .active > a:focus {
  border-color: #ddd #ddd #ddd transparent;
  *border-left-color: #ffffff;
}

 css for tabs end*/
/*QUICK LINKS END*/
/* Image Hover */
/*Signature pad css start*/
/*Signature pad css end*/
/*SMAPS STYLES START*/
/*Scrollbar css*/
/*Scrollbar css*/
/*table row bachground color*/
/*table row bachground color*/
/*SMAPS STYLES END*/
/*EasyCube*/
/*EasyCube*/
.button {
  background-image: -webkit-linear-gradient(top, #f4f1ee, #fff);
  background-image: linear-gradient(top, #f4f1ee, #fff);
  border-radius: 50%;
  box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, .3), inset 0px 4px 1px 1px white, inset 0px -3px 1px 1px rgba(204,198,197,.5);
  height: 55px;
  margin: 0 10px 10px 0;
  position: relative;
  width: 55px;
  -webkit-transition: all .1s linear;
  transition: all .1s linear;
  &:after {
    color: @color_1;
    content: "";
    display: block;
    font-size: 30px;
    height: 30px;
    text-decoration: none;
    text-shadow: 0px -1px 1px #bdb5b4, 1px 1px 1px white;
    position: absolute;
    width: 30px;
  }
  &:hover {
    background-image: -webkit-linear-gradient(top, #fff, #f4f1ee);
    background-image: linear-gradient(top, #fff, #f4f1ee);
    color: @color_3;
  }
  &:active {
    background-image: -webkit-linear-gradient(top, #efedec, #f7f4f4);
    background-image: linear-gradient(top, #efedec, #f7f4f4);
    box-shadow: 0 3px 5px 0 rgba(0,0,0,.4), inset 0px -3px 1px 1px rgba(204,198,197,.5);
    &:after {
      color: @color_4;
      text-shadow: 0px -1px 1px #bdb5b4, 0px 1px 1px white;
    }
  }
}
.inheritclr {
  color: @color_2;
}
.divhov1 {
  &:hover {
    transform: scale(1.2, 1.2);
    .fa-heartbeat {
      color: @color_6 !important;
    }
  }
}
.divhov {
  &:hover {
    .fa-calendar-o {
      color: @color_5 !important;
    }
    .fa-user-md {
      color: @color_5 !important;
    }
    .fa-plus-square {
      color: @color_5 !important;
    }
  }
}
.redcolor {
  &:hover {
    color: @color_7 !important;
  }
}
.bluecolor {
  &:hover {
    color: @color_8 !important;
  }
}
.purplecolor {
  &:hover {
    color: @color_9 !important;
  }
}
.fs_15em {
  font-size: 1.5em !important;
}
.white {
  color: @color_5 !important;
}
.p_t_b_5 {
  padding-bottom: 5px !important;
  padding-top: 5px !important;
}
.tabs-left {
  border-bottom: none;
  padding-top: 2px;
  border-right: 1px solid #ddd;
  >li {
    float: none;
    margin-bottom: 2px;
    margin-right: -1px;
    >a {
      border-radius: 4px 0 0 4px;
      margin-right: 0;
      display: block;
    }
  }
  >li.active {
    >a {
      border-bottom-color: @border_bottom_color_1;
      border-right-color: @border_right_color_1;
      &:hover {
        border-bottom-color: @border_bottom_color_1;
        border-right-color: @border_right_color_1;
      }
      &:focus {
        border-bottom-color: @border_bottom_color_1;
        border-right-color: @border_right_color_1;
      }
    }
  }
}
.tabs-right {
  border-bottom: none;
  padding-top: 2px;
  border-left: 1px solid #ddd;
  >li {
    float: none;
    margin-bottom: 2px;
    margin-left: -1px;
    >a {
      border-radius: 0 4px 4px 0;
      margin-right: 0;
    }
  }
  >li.active {
    >a {
      border-bottom: 1px solid #ddd;
      border-left-color: @border_left_color_1;
      &:hover {
        border-bottom: 1px solid #ddd;
        border-left-color: @border_left_color_1;
      }
      &:focus {
        border-bottom: 1px solid #ddd;
        border-left-color: @border_left_color_1;
      }
    }
  }
}
.sideways {
  margin-top: 50px;
  border: none;
  position: relative;
  >li {
    height: 20px;
    width: 120px;
    margin-bottom: 100px;
    >a {
      border-bottom: 1px solid #ddd;
      border-right-color: @border_right_color_1;
      text-align: center;
      border-radius: 4px 4px 0px 0px;
    }
  }
  >li.active {
    >a {
      border-bottom-color: @border_bottom_color_2;
      border-right-color: @border_right_color_2;
      border-left-color: @border_left_color_2;
      &:hover {
        border-bottom-color: @border_bottom_color_2;
        border-right-color: @border_right_color_2;
        border-left-color: @border_left_color_2;
      }
      &:focus {
        border-bottom-color: @border_bottom_color_2;
        border-right-color: @border_right_color_2;
        border-left-color: @border_left_color_2;
      }
    }
  }
}
.sideways.tabs-left {
  left: -50px;
  >li {
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
}
.sideways.tabs-right {
  right: -50px;
  >li {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}
.mb20 {
  margin-bottom: 20px !important;
}
.mar_t_b_0 {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}
.color_black {
  color: @color_10 !important;
  font-weight: bold !important;
}
.hovereffect {
  width: 100%;
  height: 100%;
  float: left;
  overflow: hidden;
  position: relative;
  text-align: center;
  cursor: default;
  .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
  .overlay1 {
    width: 90%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
  &:hover {
    .overlay {
      background-color: @background_color_1;
    }
    img {
      display: block;
      position: relative;
    }
    a.info {
      opacity: 1;
      filter: alpha(opacity=100);
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1);
      background-color: @background_color_3;
    }
  }
  h2 {
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    text-transform: uppercase;
    color: @color_11;
    text-align: center;
    position: relative;
    font-size: 17px;
    padding: 10px;
    background: rgba(0, 0, 0, 0.6);
  }
  img {
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    display: block;
    position: relative;
  }
  a.info {
    display: inline-block;
    text-decoration: none;
    padding: 7px 14px;
    text-transform: uppercase;
    color: @color_11;
    border: 1px solid #fff;
    margin: 30px 0 0 0;
    background-color: @background_color_2;
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transform: scale(1.5);
    -ms-transform: scale(1.5);
    transform: scale(1.5);
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    font-weight: normal;
    height: 85%;
    width: 85%;
    position: absolute;
    top: -20%;
    left: 8%;
  }
}
.jk {
  padding: 5px 10px;
  display: inline-block;
  font-style: normal;
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  border-radius: 100px;
  font-family: @font_family_1;
  -moz-box-shadow: 0px 0px 2px #888;
  -webkit-box-shadow: 0px 0px 2px #888;
  box-shadow: 0px 0px 2px #888;
}
.count_color {
  color: @color_12 !important;
}
.fs_xlarge {
  font-size: x-large !important;
}
.green {
  color: @color_13;
}
.blue {
  color: @color_14;
}
.padding2 {
  padding: 2px;
}
.fs20 {
  font-size: 20px !important;
}
.flipcount {
  &:hover {
    transform: rotate(360deg) scaleX(-1) !important;
  }
}
.time_underline {
  border-bottom: 1px solid #fff;
  padding-bottom: 2px;
}
#mydiv {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: @background_color_4;
  opacity: .8;
}
.loadimage {
  height: 100px;
  position: absolute;
  left: 50%;
  top: 20%;
  margin-left: -32px;
  margin-top: -32px;
  display: block;
}
.border_grey {
  border-top: 1px solid #a0aab2 !important;
}
.height25 {
  height: 25px !important;
}
.p_t_2 {
  padding-top: 2px !important;
}
.value_error {
  background-color: @background_color_5;
  color: @color_5;
  text-align: center;
  font-size: 13px;
}
.error_font {
  color: @color_15;
  text-align: center;
}
.table_head {
  >th {
    font-weight: bold !important;
    color: @color_10 !important;
  }
}
.table_td {
  >td {
    border-top: 1px solid rgba(160, 170, 178, 0.58) !important;
  }
  &:hover {
    background-color: @background_color_6 !important;
  }
}
#display {
  border: 1px solid black;
  padding: 10px;
}
#scroll {
  height: 800px;
  width: 100%;
  overflow: scroll;
  border: 1px solid blue;
}
.p0 {
  padding: 0px !important;
}
.h_w_25 {
  height: 25px !important;
  width: 25px !important;
}
.table_td_style {
  &:hover {
    background-color: @background_color_6 !important;
  }
}
.cl-fit {
  color: @color_16 !important;
}
.cl-inprogress {
  color: @color_10 !important;
}
.cl-completed {
  color: @color_17 !important;
}
.cl-unfit {
  color: @color_18 !important;
}
.cl-pending {
  color: @color_10 !important;
}
.cl-tempunfit {
  color: @color_14 !important;
}
.cl-cancel {
  color: @color_18 !important;
}
.bg_red {
  background-color: @background_color_5;
  color: @color_5 !important;
  text-align: center;
  font-size: 13px;
}
.text-black {
  color: @color_10 !important;
}
.dp {
  &:hover {
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.23), 0 3px 10px rgba(0, 0, 0, 0.16);
    cursor: pointer;
    cursor: hand;
  }
}
.finger_lg {
  border-radius: 50px !important;
  font-size: 90px !important;
}
.finger_sm {
  border-radius: 50px !important;
  font-size: 20px !important;
}
.container {
  .signature {
    border: 1px solid orange;
    margin: 0 auto;
    cursor: pointer;
    canvas {
      border: 1px solid #999;
      margin: 0 auto;
      cursor: pointer;
    }
  }
  .buttons {
    position: absolute;
    bottom: 10px;
    left: 10px;
  }
  .sizes {
    position: absolute;
    bottom: 10px;
    right: 10px;
    input {
      width: 4em;
      text-align: center;
    }
  }
}
.result {
  border: 1px solid blue;
  margin: 30px auto 0 auto;
  height: 220px;
  width: 568px;
}
.fs_14 {
  font-size: 14px !important;
}
.fs_20 {
  font-size: 20px !important;
}
#page_footer {
  position: fixed;
  bottom: 0;
  width: 100%;
}
.logoStyle {
  color: @color_19;
  line-height: 112px;
  font-size: -webkit-xxx-large;
  font-weight: 400;
  letter-spacing: 0.5px;
  font-family: @font_family_2;
}
.mt100 {
  margin-top: 100px !important;
}
.labelColor {
  color: @color_5;
  background-color: @background_color_7;
  &:focus {
    color: @color_5;
  }
}
.input_focus {
  &:focus {
    border-color: @border_color_1 !important;
  }
}
.linkStyle {
  &:hover {
    text-decoration: underline;
    text-underline-position: under;
  }
}
.bg-smaps {
  background-color: @background_color_8 !important;
  color: @color_5;
}
.mt20 {
  margin-top: 20px !important;
}
.arrow_box {
  position: relative;
  background: white;
  border: white;
  &:after {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: @border_color_2;
    border-top-color: @border_top_color_1;
    border-width: 30px;
    margin-left: -30px;
  }
  &:before {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: @border_color_3;
    border-top-color: @border_top_color_2;
    border-width: 36px;
    margin-left: -36px;
  }
}
.device_head {
  margin-bottom: 50px;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  padding: 20px;
  color: @color_20;
}
.device_list {
  color: @color_5;
  padding: 20px;
  font-size: xx-large;
  &:hover {
    .img-circle {
      cursor: pointer;
      transition: all 0.3s ease-in-out 0s;
    }
  }
}
.circle1 {
  background: url("/app/img/devices/BP.png");
  border-radius: 50%;
  height: 100px;
  width: 100px;
  transition: all .3s ease-in-out;
  &:hover {
    border-radius: 0;
    cursor: pointer;
    .text {
      padding-top: 40px;
      opacity: 1;
    }
  }
}
.text {
  color: @color_11;
  opacity: 0;
  text-align: center;
  padding-top: 0px;
  transition: all .3s ease-in-out;
}
#overlay {
  background: rgba(0,0,0,.85);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
}
#overlaySave {
  background: rgba(0,0,0,.85);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
}
.loading {
  left: 40%;
  top: 40%;
  position: fixed;
}
#style-1 {
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: @background_color_9;
  }
  &::-webkit-scrollbar {
    width: 8px;
    background-color: @background_color_9;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px #646da3;
    background-color: @background_color_10;
  }
}
.style-1 {
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: @background_color_2;
  }
  &::-webkit-scrollbar {
    width: 8px;
    background-color: @background_color_9;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px #646da3;
    background-color: @background_color_10;
  }
}
.bgclasscolor.colortd {
  transform-origin: right;
  background-color: @background_color_11 !important;
  transform: scale(0.92);
  transition-duration: 1s;
}
.bgclasscolor {
  transform: scale(1);
  transition-duration: 1s;
}
.addcheck {
  font-size: 30px !important;
  position: relative !important;
  right: 70px !important;
}
.tr_bgcolor {
  background-color: @background_color_11 !important;
}
.inputstyle {
  text-align: center;
  display: initial !important;
  width: 70px !important;
}
.myscroll {
  &:hover {
    overflow-y: scroll !important;
  }
}
.borderTransparent {
  border-color: @border_color_4 !important;
}
.ml20 {
  margin-left: 20px !important;
}
.highlight_input {
  border-bottom-color: @border_bottom_color_3;
  border-top-color: @border_top_color_3;
  border-left-color: @border_left_color_1;
  border-right-color: @border_right_color_1;
  margin-bottom: 0px;
  border-bottom-style: solid;
  outline: 0;
  border-width: 0 0 2px 0;
  border-color: @border_color_5;
}
.ml50 {
  margin-left: 50px !important;
}
.loader {
  padding-top: 250px;
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: rgb(249,249,249);
  opacity: .8;
}
.font_sf {
  font-family: @font_family_3 !important;
}
.font_wb {
  font-family: @font_family_4 !important;
}
.lable_overflow_table {
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 80px !important;
  overflow: hidden;
}
.md-datepicker-calendar-pane {
  z-index: 1200;
}
.cdk-overlay-container {
  z-index: 1200;
}
.margin20 {
  margin: 20px !important;
}
.label_display {
  font-weight: 500 !important;
  font-size: 14px !important;
}
.fw_500 {
  font-weight: 500 !important;
}
.panel-default {
  >.panel-heading {
    .testcolor {
      background-color: @background_color_12 !important;
    }
  }
}
.mt50 {
  margin-top: 50px !important;
}
.bg-NewMail {
  background-color: @background_color_13 !important;
  color: @color_21;
}
.bg-unlink {
  background-color: @background_color_14 !important;
  color: @color_21;
}
.bg-vessl {
  background-color: @background_color_15 !important;
  color: @color_21;
}
.bg-shps {
  background-color: @background_color_16 !important;
  color: @color_21;
}
.tableth_heading {
  font-size: 15px !important;
  padding: 14px 8px !important;
  color: @color_22 !important;
}
.margin_hr {
  margin-top: 8px !important;
  margin-bottom: 2px !important;
}
.col-lg-offset-05 {
  margin-left: 4%;
}
.selected_tr {
  background-color: @background_color_17 !important;
}
.selectInput {
  color: @color_23 !important;
}
.content_loader {
  position: relative;
}
.overlay_loader {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background-color: @background_color_18;
  z-index: 10;
}
.overlay-content_loader {
  position: absolute;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  top: 50%;
  left: 0;
  right: 0;
  text-align: center;
  color: @color_24;
  z-index: 1000 !important;
}
.underline_Text {
  display: inline-block;
  border-bottom: 1px solid white;
  padding-bottom: 1px;
}
.timeline_css {
  font-size: 13px !important;
  line-height: 1.52857143 !important;
  color: @color_25 !important;
}
.edit_btn_success {
  cursor: pointer;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
}
.edit_btn-cancel {
  cursor: pointer;
  background-color: @background_color_19;
  border-color: @border_color_6;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 15px;
  padding-right: 15px;
}
.ul_style {
  padding-left: 25px;
  padding-top: 20px;
  border-bottom-width: 20px;
  padding-bottom: 20px;
}
.Suucesscolor {
  color: @color_13 !important;
}
.ErrorColor {
  color: @color_7 !important;
}
@media (min-width: 768px) and (max-width: 1024px) {
  .input-xs {
    min-width: 200px !important;
  }
}
@media (min-width: 320px) and (max-width: 480px) {
  .input-xs {
    min-width: 152px;
  }
}
@media only screen and (min-width: 1000px) {
  .topMarginHeight {
    margin-top: 200px !important;
  }
}
@media only screen and (max-width: 400px) {
  .topMarginHeight {
    margin-top: 100px !important;
  }
}
.container-xs {
  max-width: 450px;
  width: auto;
}
.selectedMail{
  background-color: #fce48d !important;
}
.qnCategoryActive {
  background-color: #f34235;
  color: white;
}
#qnCategories:hover{
  background-color: #f34235;
  color: white;
}
/*css for file upload drop zone*/
.drop-box {
  background: #F8F8F8;
  border: 5px dashed #DDD;
  width: 100%;
  height: 100px;
  text-align: center;
  padding-top: 25px;
  margin: 10px;
}
.dragover {
  border: 5px dashed blue;
}
.disabled {
  cursor: not-allowed;
}
.tr_bgcolor{background-color:#FFD699!important}
.bghigh {
  background-color: #aae3aa;
}
.alert_color {
  color: #2248cf !important;
}
.imgclass1 {
  -ms-transition: -ms-transform 0.8s ease-in-out;
  -webkit-transition: -webkit-transform 0.8s ease-in-out;
  transition: transform 1s ease-in-out;
}
.imgclass1:hover {
  -ms-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  cursor: pointer;
  transform: scale(1.1);
}
.imgclass {
  height: 193px !important;
  width: 195px !important;
}
.hidebutton {
  display: none;
}

.hidebutton_edit {
  display: none;
}
.displaybutton:hover .hidebutton {
  position: absolute;
  left: 197px;
  display: block;
  z-index: 10000;
}

.displaybutton:hover .hidebutton_edit {
  position: absolute;
  left: 176px;
  display: block;
  z-index: 10000;
}
//video {
//  width: 100% !important;
//  height: 100% !important;
//}
#video-container video {
  position: relative;
  float: left;
  width: 30%;
  cursor: pointer;
  padding: 5px;
}
#main-video video {
  width: 100%;
}
.smoothy:after {
  display: none;
}
.dispalyNone{
  display: none !important;
}